import { useState, useEffect } from "react";
import './style.scss';
import Table from 'react-bootstrap/Table';

import taskApi from "../../services/taskService";
import roleApi from "../../services/roleService";
import userApi from "../../services/userService";
import { FaEdit } from "react-icons/fa";
import InputMask from 'react-input-mask'
import { Button, Col, Container, Form, Row } from "react-bootstrap";

interface data{
    id?: string,
    name?: string,
    description?: string,
    frequency?: string,
    type?: string,
    alert_to?: string,
    user_role_id?: string,
    user_id?: string,
    when?: string,
    user? : any,
    role? : any
}
type valuesCrud = data[];
const initialValue = {
    id: undefined,
    name: '',
    description: '',
    frequency: '',
    type: '',
    alert_to: '',
    user_role_id: undefined,
    user_id: undefined,
    when: ''
}

interface dataRole {
    id: any,
    name: string
}
type valuesRole = dataRole[];
  
export default function Task() {
    const [tasks, setTasks] = useState<valuesCrud>([]);
    const [users, setUsers] = useState<valuesRole>([]);
    const [roles, setRoles] = useState<valuesRole>([]);
    const [task, setTask] = useState<data>(initialValue);
    const [editTask, setEditTask] = useState<boolean>(false);
    useEffect(() => {
        getTasks();
    }, []);
  
    async function getTasks(){
        let response = await taskApi.getAll();
        console.log(response);
        setTasks(response.data);
    }
    async function save() {
        if (task.id) {
            let response = await taskApi.update(task);
        } else {
            let response = await taskApi.save(task);
        }
        setEditTask(false);
        setTask(initialValue);
        getTasks();
    }
    async function getRoles() {
        let response = await roleApi.getAll();
        setRoles(response.data);
    }
    async function getUsers() {
        let response = await userApi.getAll();
        setUsers(response.data);
    }
    return (
      <section className="page-home">
            <div className="card">      
                {!editTask ? 
                    <>
                        <div className="header-crud">
                            <h1>Tarefas</h1>
                            <div className="float-right">
                                <Button variant="primary" onClick={() => {
                                    setTask(initialValue);
                                    setEditTask(true);
                                    getRoles();
                                    getUsers();
                                }}>
                                    Criar Tarefa
                                </Button>{'  '}
                            </div>
                        </div>
                        <Table striped>
                            <thead>
                                <tr>
                                    <th>Tarefa</th>
                                    <th>Área</th>
                                    <th>Recorrencia</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                { tasks && tasks.length > 0 && tasks.map((row) => 
                                    <tr key={row.id}>
                                        <td>{row.name}</td>
                                        <td>{row.role ? row.role.name : 'Não atribuido'}</td>
                                        <td>{row.user ? row.user.name : 'Não atribuido'}</td>
                                        <td>
                                            <a href="#" onClick={() => {
                                                setEditTask(true); 
                                                setTask(row)}}><FaEdit /></a>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </>
                :
                    <Container>
                        <Form.Group as={Row}>
                            <Col sm={12}>
                                <Form.Label>Nome</Form.Label>
                                <Form.Control type="name" placeholder="Nome" 
                                    value={task.name ? task.name : "" }
                                    onChange={(val) => setTask({...task, name: val.target.value})} 
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col sm={12}>
                                <Form.Label>Descrição</Form.Label>
                                <Form.Control as="textarea" rows={3}
                                    value={task.description ? task.description : "" }
                                    onChange={(val) => setTask({...task, description: val.target.value})} 
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col sm={6}>
                                <Form.Label>Área Responsável</Form.Label>
                                <Form.Select 
                                    aria-label="Cargo"
                                    value={task.user_role_id ? task.user_role_id : '' }
                                    onChange={(val) => setTask({...task, user_role_id: val.target.value})} 
                                >
                                    <option value={''}>Selecione</option>
                                    { roles && roles.length > 0 && roles.map((role) =>
                                        <option value={role.id}>{role.name}</option>
                                    )}
                                </Form.Select>
                            </Col>
                            <Col sm={6}>
                                <Form.Label>Usuário Responsável</Form.Label>
                                <Form.Select 
                                    aria-label="Usuário"
                                    value={task.user_id ? task.user_id : '' }
                                    onChange={(val) => setTask({...task, user_id: val.target.value})} 
                                >
                                    <option value={''}>Selecione</option>
                                    { users && users.length > 0 && users.map((user) =>
                                        <option value={user.id}>{user.name}</option>
                                    )}
                                </Form.Select>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col sm={4}>
                                <Form.Label>Recorrencia</Form.Label>
                                <Form.Select 
                                    aria-label="Recorrencia"
                                    value={task.frequency ? task.frequency : '' }
                                    onChange={(val) => setTask({...task, frequency: val.target.value})} 
                                >
                                    <option value={''}>Selecione</option>
                                    <option value="single">Avulso</option>
                                    <option value="daily">Diário</option>
                                    <option value="weekly">Semana</option>
                                    <option value="monthly">Mensal</option>
                                </Form.Select>
                            </Col>
                            <Col sm={4}>
                                <Form.Label>Quando?</Form.Label>
                                <InputMask 
                                    type="text" 
                                    placeholder="09:00" 
                                    className='form-control'
                                    value={task.when ? task.when : "" }
                                    onChange={(val) => setTask({...task, when: val.target.value})} 
                                    mask="99:99"
                                />
                            </Col>
                            <Col sm={4}>
                                <Form.Label>Tipo</Form.Label>
                                <Form.Select 
                                    aria-label="Recorrencia"
                                    value={task.type ? task.type : '' }
                                    onChange={(val) => setTask({...task, type: val.target.value})} 
                                >
                                    <option value={''}>Selecione</option>
                                    <option value="check">Confirmar</option>
                                    <option value="text">Resposta de texto</option>
                                </Form.Select>
                            </Col>
                        </Form.Group>
                        <Button variant="primary" onClick={() => save()}>Salvar</Button>
                        <Button variant="secondary" onClick={() => setEditTask(false)}>Cancelar</Button>
                    </Container>
                }
            </div>
      </section>
    );
}
  