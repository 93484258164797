import api from './api';

const user = {
    getAll: async() => {
        let i = localStorage.getItem('i');
        return await api.get('/user?count=100&page=1&i='+i)
        .then((response:any) => {
            return response.data;
        })
        .catch((error:any) => {
            console.log(error.response);
            return null;
        });
    },
    save: async(data : any) => {
        let i = localStorage.getItem('i');
        return await api.post('/user?i='+i, data)
        .then((response:any) => {
            return response.data;
        })
        .catch((error:any) => {
            console.log(error.response);
            return null;
        });
    },
    put: async(data : any) => {
        let i = localStorage.getItem('i');
        return await api.put('/user/'+data.id+'?i='+i, {data: data})
        .then((response:any) => {
            return response.data;
        })
        .catch((error:any) => {
            console.log(error.response);
            return null;
        });
    },

}

export default user;