import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../context/globalContext";
import './style.scss';
import { FaTrash, FaTimes } from "react-icons/fa";
import logo from '../../img/logo.svg'

import { useHistory } from "react-router-dom";
import { ReactComponent as LoadingButton } from '../../assets/loading.svg';

import { Button, Col, Container, Form, Row, ListGroup, ListGroupItem } from "react-bootstrap";

import authApi from "../../services/auth";
import userApi from "../../services/userService";
import departmentApi from "../../services/departmentService";
import companyApi from "../../services/companyService";
import department from "../../services/departmentService";

interface dadosUser {
    id?: number,
    email: string,
    name: string,
    error?: boolean,
    is_manager?: boolean
}

interface departmentData {
    id?: number,
    name: string,
    user_admin?: dadosUser,
    users?: any[],
    status?: boolean,
}

interface selectedsUsers {
    [index: number]: string;
};

const inicialUser = {
    email: '',
    name: '',
    error: false,
    is_manager: false,
}

const initialDepartment = {
    name: '',
    users: [],
    user_admin: undefined,
    status: true
}

export default function SetupPage() {
    const [step, setStep] = useState(1);
    const [userList, setUserList] = useState<Array<dadosUser>>([]);
    const [departmentList, setDepartmentList] = useState<Array<departmentData>>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [msgError, setMsgError] = useState('');
    const [selectedUser, setSelectedUser] = useState<selectedsUsers>([]);
    const [company, setCompany] = useState({ name: "" });

    const context = useContext<any>(GlobalContext);

    useEffect(() => {
        setError(false);
        setMsgError('');
        let f = [inicialUser];
        setUserList(f);
        let a = [initialDepartment];
        setDepartmentList(a);

        getCompany();
        getUsers();
        getDepartments();
    }, []);

    async function getCompany() {
        let response = await companyApi.get();
        setCompany(response);
    }
    async function getUsers(){
        let response = await userApi.getAll();
        setUserList(response.data);
    }
    async function getDepartments(){
        let response = await departmentApi.getAll();
        setDepartmentList(response.data);
    }

    async function save(e: any) {
        e.preventDefault();
        setLoading(true);
        setError(false);

        if (userList.length === 0) {
            setError(true);
            setMsgError('Adicione pelo menos um colaborador');
            setLoading(false);
            return false;
        }
        if (departmentList.length === 0) {
            setError(true);
            setMsgError('Adicione pelo menos uma área');
            setLoading(false);
            return false;
        }
        let user = departmentList.find((department) => department.users?.length === 0);
        if (user) {
            setError(true);
            setMsgError('Adicione pelo menos um colaborador para cada área');
            setLoading(false);
            return false;
        }
        let userDepartment = departmentList.find((department) => department.users?.find((user) => user.email === ""));
        if (userDepartment) {
            setError(true);
            setMsgError('Selecione pelo menos uma área para cada colaborador');
            setLoading(false);
            return false;
        }
        let userDuplicate = userList.find((user, index) => userList.findIndex((user2) => user2.email === user.email) !== index);
        if (userDuplicate) {
            setError(true);
            setMsgError(`Não é permitido emails duplicados (${userDuplicate.name} - ${userDuplicate.email})`);
            setLoading(false);
            return false;
        }
        let departmentDuplicate = departmentList.find((department, index) => departmentList.findIndex((department2) => department2.name === department.name) !== index);
        if (departmentDuplicate) {
            setError(true);
            setMsgError('Não é permitido áreas duplicadas');
            setLoading(false);
            return false;
        }
        let data = {
            users: userList,
            departments: departmentList,
        }
        
        authApi.setUp(data)
        .then((response:any) => {
            setLoading(false);
            window.location.href = window.location.origin+'/admin';
        })
        .catch((error:any) => {
            setLoading(false);
            setError(true);
            switch (error.data.error) {
                default:
                    setMsgError('Erro ao salvar');
            }
            
        });
    }

    async function nextStep() {
        setError(false);

        switch (step) {
            case 1:
                if (userList.length === 0) {
                    setError(true);
                    setMsgError('Adicione pelo menos um colaborador');
                    return false;
                }
                if (userList.some((user) => user.error)) {
                    setError(true);
                    setMsgError('O email deve ser único');
                    return false;
                }
                setStep(2);
                break;
            case 2:
                if (departmentList.length === 0) {
                    setError(true);
                    setMsgError('Adicione pelo menos uma área');
                    return false;
                }
                setStep(3);
                break;
            case 3:
                if (departmentList.some((department) => !department.user_admin)) {
                    setError(true);
                    setMsgError('Defina um responsável por área');
                    return false;
                }
                setStep(4);
                break;
        
            default:
                break;
        }
    }

    function showMsgError(){
        if(error){
            return <div className='msg-error'>{msgError}</div>;
        }
    }

    function addUser() {
        let f = [...userList];
        f.push(inicialUser);
        setUserList(f);
    }

    function addDepartment() {
        let a = [...departmentList];
        a.push(initialDepartment);
        setDepartmentList(a);
    }

    function addUserToDepartment(index: number, userEmail: string) {
        if (departmentList[index].users?.some((user) => user.email === userEmail)) return;
        const user = userList.find((user) => user.email === userEmail);
        if (!user) return;
        const updatedDepartmentList = departmentList.map((department, i) => {
            if (i === index) {
                const updatedUsers = department.users ? [...department.users, user] : [user];
                return { ...department, users: updatedUsers };
            }
            return department;
        });
        setDepartmentList(updatedDepartmentList);
        selectedUser[index] = "";
        setSelectedUser(selectedUser);
    }

    function removeUser(index: number) {
        let f = [...userList];
        f.splice(index, 1);
        setUserList(f);
    }

    function removeDepartment(index: number) {
        let a = [...departmentList];
        a.splice(index, 1);
        setDepartmentList(a);
    }

    return (
        <div className="page-login">
            <div className="formSetup">
                <img src={logo} alt="" />
                <div className="companyName">
                    <h3>{company.name ?? ""}</h3>
                </div>
                <div className="close">
                    <FaTimes onClick={() => window.location.href = window.location.origin + '/admin'} />
                </div>
                {step === 1 &&
                    <div className="boxLogin">
                        <h2>Colaboradores</h2>
                        <p>Faça uma lista com todos os emails dos colaboradores que utilizarão o aplicativo</p>
                        <form>
                            <Container>
                                {userList.length > 0 && userList.map((item, index) => 
                                    <Form.Group as={Row} key={index}>  
                                        <Col sm={5}>
                                            <Form.Label>Nome</Form.Label>
                                            <Form.Control type="name" placeholder="Nome" 
                                                value={item.name}
                                                onChange={(val) => setUserList((userList) => [
                                                    ...userList.slice(0,index),
                                                    {
                                                        ...userList[index],
                                                        name: val.target.value,
                                                    },
                                                    ...userList.slice(index+1)
                                                ])} 
                                            />
                                        </Col>
                                        <Col sm={4}>
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control type="email" placeholder="Email" 
                                                name="email"
                                                required={true}
                                                value={item.email}
                                                isInvalid={item.error}
                                                onChange={(val) => {
                                                    let alreadyExist = userList.some((user) => user.email === val.target.value);
                                                    setUserList((userList) => [
                                                        ...userList.slice(0,index),
                                                        {
                                                            ...userList[index],
                                                            email: val.target.value,
                                                            error: alreadyExist
                                                        },
                                                        ...userList.slice(index+1)
                                                    ]);
                                                }}
                                            />
                                            {item.error && <p className="legend error">O email deve ser único</p>}
                                        </Col>
                                        <Col sm={2}>
                                            <Form.Label>Administrador?</Form.Label><br/>
                                            <Form.Check
                                                type="switch"
                                                checked={item.is_manager}
                                                onChange={(val) => {
                                                    setUserList((userList) => [
                                                        ...userList.slice(0,index),
                                                        {
                                                            ...userList[index],
                                                            is_manager: !item.is_manager,
                                                        },
                                                        ...userList.slice(index+1)
                                                    ]);
                                                }}
                                            />
                                        </Col>
                                        <Col sm={1}>
                                            <br/>
                                            { !item.id &&
                                                <FaTrash onClick={() => removeUser(index)}/>
                                            }
                                        </Col>
                                        

                                    </Form.Group>
                                )}
                                <Col sm={12}>
                                    <br/>
                                    <button type="button" className="btn btn-outline-success btn-sm btn-block" onClick={() => addUser()}>Adicionar colaborador</button>
                                </Col>
                            </Container>
                            
                            
                            {showMsgError()}
                            <div className="actions">
                                <button type="button" onClick={() => nextStep()}>Continuar</button>
                            </div>
                        </form>
                    </div>
                }
                {step === 2 &&
                    <div className="boxLogin">
                        <h2>Áreas</h2>
                        <p>Defina as áreas que gostaria de gerenciar</p>
                        <form onSubmit={save}>
                            <Container>
                                {departmentList.length > 0 && departmentList.map((item, index) => 
                                    <Form.Group as={Row} key={index}>  
                                        <Col sm={9}>
                                            <Form.Label>Nome</Form.Label>
                                            <Form.Control type="name" placeholder="Nome" 
                                                value={item.name}
                                                onChange={(val) => setDepartmentList((departmentList) => [
                                                    ...departmentList.slice(0,index),
                                                    {
                                                        ...departmentList[index],
                                                        name: val.target.value,
                                                    },
                                                    ...departmentList.slice(index+1)
                                                ])} 
                                            />
                                        </Col>
                                        <Col sm={2}>
                                            <Form.Label>Status</Form.Label><br />
                                            <Form.Check
                                                type="switch"
                                                checked={item.status}
                                                onChange={(val) => setDepartmentList((departmentList) => [
                                                    ...departmentList.slice(0, index),
                                                    {
                                                        ...departmentList[index],
                                                        status: !item.status,
                                                    },
                                                    ...departmentList.slice(index + 1)
                                                ])}
                                            />
                                        </Col>
                                        <Col sm={1}>
                                            <br/>
                                            { !item.id &&
                                                <FaTrash onClick={() => {removeDepartment(index)}} />
                                            }
                                        </Col>
                                        
                                    </Form.Group>
                                )}
                                <Col sm={12}>
                                    <br/>
                                    <button type="button" className="btn btn-outline-success btn-sm btn-block" onClick={() => addDepartment()}>Adicionar área</button>
                                </Col>
                            </Container>
                            
                            
                            {showMsgError()}
                            <div className="actions">
                                <button type="button" className="btn-invert" onClick={() => setStep(1)}>Voltar</button>
                                <button type="button" onClick={() => nextStep()}>Continuar</button>
                            </div>
                        </form>
                    </div>
                }
                {step === 3 &&
                    <div className="boxLogin">
                        <h2>Responsáveis</h2>
                        <p>Defina um responsável por área (um usuário pode gerenciar mais de uma área.)</p>
                        <form onSubmit={save}>
                            <Container>
                                {step == 3 && departmentList.length > 0 && departmentList.map((item, index) => 
                                    <Form.Group as={Row} key={index}>  
                                        <Col sm={6}>
                                            <Form.Label>Department</Form.Label>
                                            <Form.Control type="name" placeholder="Nome" 
                                            value={item.name}
                                            disabled
                                            />
                                        </Col>
                                        <Col sm={6}>
                                            <Form.Label>Responsável</Form.Label>
                                            <Form.Select 
                                                aria-label="Responsável"
                                                value={item.user_admin?.email}
                                                onChange={(val) => {
                                                    let departmentListObj = departmentList;
                                                    departmentListObj.map((department, i) => {
                                                        if (i === index) {
                                                            department.user_admin = {
                                                                email : val.target.value,
                                                                name : userList.find((user) => user.email === val.target.value)?.name || "",
                                                            }
                                                        }
                                                    });
                                                    setDepartmentList(departmentListObj);
                                                    addUserToDepartment(index, val.target.value);
                                                }}
                                            >
                                                <option value={''}>Selecione</option>
                                                { userList.length > 0 && userList.map((user, index) =>
                                                    <option key={index} value={user.email}>{user.name}</option>
                                                )}
                                            </Form.Select>
                                        </Col>
                                    </Form.Group>
                                )}
                            </Container>
                            
                            
                            {showMsgError()}
                            <div className="actions">
                                <button type="button" className="btn-invert" onClick={() => setStep(2)}>Voltar</button>
                                <button type="button" onClick={() => nextStep()}>Continuar</button>
                            </div>
                        </form>
                    </div>
                }         
                {step === 4 &&
                    <div className="boxLogin">
                        <h2>Colaboradores das áreas</h2>
                        <p>Defina a área de cada colaborador (Um colaborador pode atuar em mais de uma área)</p>
                        <form onSubmit={save}>
                            <Container>
                                <Row>
                                    {departmentList.length > 0 && departmentList.map((item, index) => 
                                        <Col sm={4} key={index}>
                                            <div className="department">
                                                <h4>{item.name}</h4>
                                                <b className="legend primary-color">Responsável: {item.user_admin?.name}</b>
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                <select className="form-control"
                                                                    value={selectedUser[index]}
                                                                    onChange={(val) => {
                                                                        let selected = [];
                                                                        selected[index] = val.target.value;
                                                                        setSelectedUser(selected);
                                                                    }}
                                                                >
                                                                    <option value="">Selecione</option>
                                                                    { userList.length > 0 && userList.map((user, index) =>
                                                                        <option key={index} value={user.email}>{user.name}</option>
                                                                    )}
                                                                </select>
                                                            </th>
                                                            <th>
                                                                <button type="button" className="btn btn-outline-success btn-sm btn-block" onClick={() => addUserToDepartment(index, selectedUser[index])}>Adicionar colaborador</button>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {item && item.users && item.users.length > 0 && item.users.map((user) =>
                                                            <tr>
                                                                <td>{user.name}</td>
                                                                <td>{user.email}</td>
                                                                <td><FaTimes onClick={
                                                                    () => setDepartmentList((departmentList) => [
                                                                        ...departmentList.slice(0,index),
                                                                        {
                                                                            ...departmentList[index],
                                                                            users: departmentList[index].users?.filter((user2) => user2.email !== user.email),
                                                                        },
                                                                        ...departmentList.slice(index+1)
                                                                    ])
                                                                }/></td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Col>
                                    )}
                                </Row>
                                {userList.length > 0 && <h4>Coloboradores sem área</h4>}
                                <ListGroup>
                                    {userList.length > 0 && userList.map((user, index) => {
                                        let userDepartment = departmentList.find((department) => department.users?.find((user2) => user2.email === user.email));
                                        if (!userDepartment) {
                                            return <ListGroupItem className="user" key={index}>
                                                <p>{user.name}</p>
                                                <p>{user.email}</p>
                                            </ListGroupItem>
                                        }
                                    })}
                                </ListGroup>
                            </Container>
                            
                            
                            {showMsgError()}
                            <div className="actions">
                                <button type="button" className="btn-invert" onClick={() => setStep(3)}>Voltar</button>
                                <button type="submit" >{loading ? <LoadingButton /> : 'Finalizar'}</button>
                            </div>
                        </form>
                    </div>
                }        
                <p>by. Minha Plataforma</p> 
            </div>
        </div>
    );
}
