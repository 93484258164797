import api from './api';

const company = {
    getAll: async() => {
        return await api.get('/company')
            .then((response: any) => {
                return response.data;
            })
            .catch((error: any) => {
                console.log(error.response);
                return null;
            });
    },
    add: async(data : any) => {
        return await api.post('/company/attach_user', data)
            .then((response: any) => {
                return response.data;
            })
            .catch((error: any) => {
                console.log(error.response);
                return null;
            });
    },
    get: async () => {
        let i = localStorage.getItem('i');
        return await api.get('/company/' + i)
            .then((response: any) => {
                return response.data;
            })
            .catch((error: any) => {
                console.log(error.response);
                return null;
            });
    },
    update: async(data : any) => {
        return await api.put('/company/'+data.id, data)
        .then((response:any) => {
            return response.data;
        })
        .catch((error:any) => {
            console.log(error.response);
            return null;
        });
    },

}

export default company;