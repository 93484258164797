import React, { useState, FunctionComponent } from 'react';
import {authApi} from "../services/auth";

export interface dataUser {
    email: string;
    password: string;
    token?: string;
}

export interface LayoutProps {
    error: string | null;
    loading: any;
    user: any;
    setUser: (value: any) => void;
    onLogout: () => void;
    onLogin: (value: dataUser) => void;
    onSignup: (value: dataUser) => void;
    getMe: (value: dataUser) => void;
}

export const GlobalContext = React.createContext({} as LayoutProps);

function useProvideLayout(): LayoutProps {
    const [i, setI] = useState<any>();
    const [user, setUser] = useState<any>();
    const [loading, setLoading] = useState<any>(true);
    const [error, setError] = useState(null);

    async function onLogout() {
        localStorage.removeItem('token')
        window.location.href = window.location.origin+'/login';
    }

    async function onLogin() {
        setLoading(true);
        await getMe();
        if (localStorage.getItem('token')) {
            window.location.href = window.location.origin+'/admin';
        }
        
        return true;
    }

    async function onSignup() {
        setLoading(true);
        await getMe();
        if (localStorage.getItem('token')) {
            window.location.href = window.location.origin + '/setup';
        }
        return true;
    }

    async function getMe() {
        setLoading(true);
        let token = localStorage.getItem('token');
        
        if(token){
            let response = await authApi.me();
            if (response.permissions && response.permissions.length > 0) {
                let hasAdmin = response.permissions.some((item: any) => { return item.name === 'admin' || item.name === 'manager'; });
                if (!hasAdmin) {
                    alert('Você não tem permissão para acessar o sistema.');
                    return onLogout();
                }
            }
            if (response.id) {
                setUser(response);
                if (!localStorage.getItem('token') && response.companies && response.companies.length > 0) {
                    localStorage.setItem('i', response.companies[0].id);
                }
            }
        }
        setLoading(false);
    }

    return {
        error,
        loading,
        user,
        setUser,
        onLogout,
        onLogin,
        onSignup,
        getMe,
    };
}

export const GlobalProvider: FunctionComponent = ({ children }) => {
    const layout: LayoutProps = useProvideLayout();
    return <GlobalContext.Provider value={layout}>{children}</GlobalContext.Provider>;
};