import { useState, useEffect } from "react";
import './style.scss';
import Table from 'react-bootstrap/Table';

import userApi from "../../services/userService";
import roleApi from "../../services/roleService";
import { FaEdit } from "react-icons/fa";
import InputMask from 'react-input-mask'
import { Button, Col, Container, Form, Row } from "react-bootstrap";

interface dataUser {
    id: any,
    email: string,
    instagram: string,
    linkedin: string,
    name: string,
    occupation: string,
    phone_number: string,
    role: string,
    user_role_id?: string,
    whats_phone: string,
    cpf: string
}
type valuesCrud = dataUser[];
const initialUser = {
    id: null,
    email: '',
    instagram: '',
    linkedin: '',
    name: '',
    occupation: '',
    phone_number: '',
    role: '',
    whats_phone: '',
    user_role_id: '',
    cpf: ''
}

interface dataRole {
    id: any,
    name: string
}
type valuesRole = dataRole[];
  
export default function Users() {
    const [users, setUsers] = useState<valuesCrud>([]);
    const [roles, setRoles] = useState<valuesRole>([]);
    const [user, setUser] = useState<dataUser>(initialUser);
    const [editUser, setEditUser] = useState<boolean>(false);
    useEffect(() => {
        getUsers();
    }, []);
  
    async function getUsers(){
        let response = await userApi.getAll();
        setUsers(response.data);
    }
    async function save() {
        let response = null;
        if (user.id)
            response = await userApi.put(user); 
        else
            response = await userApi.save(user); 

        if (response) {
            setUser(initialUser);
            setEditUser(false);
            getRoles();
        }

    }
    async function getRoles() {
        let response = await roleApi.getAll();
        setRoles(response.data);
    }
    return (
      <section className="page-home">
            <div className="header-crud">
                <h1>Usuários</h1>
            </div>
            <div className="card">
                {!editUser ? <>
                    <div className="header-crud">
                        <div className="float-right">
                            <Button variant="primary" onClick={() => {
                                setUser(initialUser);
                                setEditUser(true);
                                getRoles();
                            }}>
                                Criar Usuário
                            </Button>{'  '}
                        </div>
                    </div>
                    <Table striped bordered>
                        <thead>
                            <tr>
                                <th>Nome</th>
                                <th>Email</th>
                                <th>Telefone</th>
                                <th>Tipo</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            { users && users.length > 0 && users.map((row) => 
                                <tr key={row.id}>
                                    <td>{row.name}</td>
                                    <td>{row.email ? row.email : 'Sem nome'}</td>
                                    <td>{row.phone_number ? row.phone_number : 'Sem email'}</td>
                                    <td>{row.role === 'client' ? 'Cliente' : (
                                        row.role == 'admin' ? 'Administrador' : 'Não definido'
                                    )}</td>
                                    <td>
                                        <a href="#" onClick={() => {
                                            setEditUser(true); 
                                            setUser(row);
                                            getRoles();
                                        }}><FaEdit /></a>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </>:
                    <Container>
                        <Form.Group as={Row} >
                            <Col sm={8}>
                                <Form.Label>Nome</Form.Label>
                                <Form.Control type="name" placeholder="Nome" 
                                    value={user.name ? user.name : "" }
                                    onChange={(val) => setUser({...user, name: val.target.value})} 
                                />
                            </Col>
                            <Col>
                                <Form.Label>Tipo</Form.Label>
                                <Form.Select 
                                    aria-label="Tipo"
                                    value={user.user_role_id ? user.user_role_id : '' }
                                    onChange={(val) => setUser({...user, user_role_id: val.target.value})} 
                                >
                                    { roles && roles.length > 0 && roles.map((role) =>
                                        <option key={role.id} value={role.id}>{role.name}</option>
                                    )}
                                </Form.Select>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col>
                                <Form.Label>CPF</Form.Label>
                                <InputMask
                                    autoFocus
                                    type="text" 
                                    className='form-control' 
                                    placeholder='CPF'
                                    value={user.cpf ? user.cpf : "" }
                                    onChange={(val) => setUser({ ...user, cpf: val.target.value })}
                                    mask="999.999.999-99"
                                />
                            </Col>
                            <Col>
                                <Form.Label>Telefone</Form.Label>
                                <InputMask
                                    autoFocus
                                    type="text" 
                                    className='form-control' 
                                    placeholder='Telefone'
                                    value={user.phone_number ? user.phone_number : "" }
                                    onChange={(val) => setUser({ ...user, phone_number: val.target.value })}
                                    mask="(99) 99999-9999"
                                />
                            </Col>
                            <Col>
                            <Form.Label>Telefone</Form.Label>
                                <InputMask
                                    autoFocus
                                    type="text" 
                                    className='form-control' 
                                    placeholder='WhatsApp'
                                    value={user.whats_phone ? user.whats_phone : "" }
                                    onChange={(val) => setUser({ ...user, whats_phone: val.target.value })}
                                    mask="(99) 99999-9999"
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} >
                            <Col sm={8}>
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" placeholder="Email" 
                                    value={user.email ? user.email : "" }
                                    onChange={(val) => setUser({...user, email: val.target.value})} 
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} >
                            <Col>
                                <Form.Label>Instagram</Form.Label>
                                <Form.Control type="name" placeholder="Instagram" 
                                    value={user.instagram ? user.instagram : "" }
                                    onChange={(val) => setUser({...user, instagram: val.target.value})} 
                                />
                            </Col>
                            <Col>
                                <Form.Label>LinkedIn</Form.Label>
                                <Form.Control type="name" placeholder="linkedin" 
                                    value={user.linkedin ? user.linkedin : "" }
                                    onChange={(val) => setUser({...user, linkedin: val.target.value})} 
                                />
                            </Col>
                        </Form.Group>
                        <Button variant="primary" onClick={() => save()}>Salvar</Button>{'  '}
                        <Button variant="secondary" onClick={() => setEditUser(false)}>Cancelar</Button>
                    </Container>
                }
            </div>
      </section>
    );
}
  