import axios from "axios";
// import { getToken } from "./auth";
import { useContext } from "react";
import { GlobalContext } from "../context/globalContext";
// import { withRouter } from "react-router-dom";

const api:any = axios.create({
    // baseURL: "http://localhost:8000/api", // Local
    baseURL: "https://app.falefy.com/api", // Prod
});
export const apiNotAuthenticate:any = axios.create({
    // baseURL: "http://localhost:8000/api", // Local
    baseURL: "https://app.falefy.com/api", // Prod
});

apiNotAuthenticate.interceptors.request.use(async (config: any) => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers.Authorization = 'Bearer '+token;
    }
    return config;
});

api.interceptors.request.use(async (config: any) => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers.Authorization = 'Bearer '+token;
    }
    return config;
});

api.interceptors.response.use(function (response: object) {
    return response;
}, async function (error: any) {
    if (error.response.status == 401) {
        let response = await RefreshToken.refreshToken();
        if (response.hasOwnProperty('access_token')){
            error.config.headers.Authorization = 'Bearer '+response.access_token;
            return axios(error.config);
        }
        localStorage.removeItem('token')
        window.location.href = window.location.origin+'/login';
    }
    return Promise.reject(error);
});

export const RefreshToken = {
    refreshToken: async() => {
        return await apiNotAuthenticate.post('/refresh')
        .then((response:any) => {
            if (response.data.access_token) {
                localStorage.setItem("token", response.data.access_token);
            }
            api.defaults.headers.common['Authorization'] = "Bearer "+response.data.access_token;
            return response.data;
        })
        .catch((error:any) => {
            return error.response;
        });
    },
}
export default api;
