import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { GlobalContext } from "../../context/globalContext";
import './style.scss';

import InputMask from 'react-input-mask';
import logo from '../../img/logo.png'
import { ReactComponent as LoadingButton } from '../../assets/loading.svg';
import authApi from "../../services/auth";

type dadosUser = {
    name: string;
    email: string;
    password: string;
    confirm: string;
    company: string;
    cnpj: string;
    code: string;
}

const inicialForm: dadosUser = {
    name: '',
    email: '',
    password: '',
    confirm: '',
    company: '',
    cnpj: '',
    code: ''
}

export default function LoginPage() {
    const [page, setPage] = useState('login');
    const [form, setForm] = useState<dadosUser>(inicialForm);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [msgError, setMsgError] = useState('');

    const context = useContext<any>(GlobalContext);

    async function logar() {
        setLoading(true);
        setError(false);
        if (form.email === '') {
            setError(true);
            setMsgError('Digite o seu e-mail');
            setLoading(false);
            return false;
        }
        if (form.password === '') {
            setError(true);
            setMsgError('Digite sua senha');
            setLoading(false);
            return false;
        }
        await authApi.login(form)
        .then((response:any) => {
            setLoading(false);
            context.onLogin();
        })
        .catch((error:any) => {
            setLoading(false);
            setError(true);
            switch (error.data.error) {
                case "invalid_credentials":
                    setMsgError('Email ou senha inválido!');
                    break;
                default:
                    setMsgError('Email ou senha inválido!');
                    break;
            }
            return false;
        });
    }

    async function register() {
        setLoading(true);
        setError(false);
        if (form.email === '') {
            setError(true);
            setMsgError('Digite o seu e-mail');
            setLoading(false);
            return false;
        }
        if (form.password === '') {
            setError(true);
            setMsgError('Digite sua senha');
            setLoading(false);
            return false;
        }
        if (form.company === '') {
            setError(true);
            setMsgError('Digite o nome da empresa');
            setLoading(false);
            return false;
        }
        if (form.name === '') {
            setError(true);
            setMsgError('Digite seu nome');
            setLoading(false);
            return false;
        }
        if (form.cnpj === '') {
            setError(true);
            setMsgError('Digite o CNPJ');
            setLoading(false);
            return false;
        }
        if (form.password !== form.confirm) {
            setError(true);
            setMsgError('As senhas não conferem');
            setLoading(false);
            return false;
        }

        await authApi.signup(form)
        .then((response:any) => {
            setLoading(false);
            context.onSignup();
        })
        .catch((error:any) => {
            setLoading(false);
            setError(true);
            switch (error.data.error) {
                case "company_exists":
                    setMsgError('Empresa já cadastrada, faça login para gerenciar sua empresa');
                    break;
                case "user_exists":
                    setMsgError('Usuário já cadastrado, faça login para criar ume empresa nova');
                    break;
                default:
                    setMsgError('Erro desconhecido, entre em contato com o suporte');
                    break;
            }
            return false;
        });
    }

    async function requestPassword() {
        setLoading(true);
        setError(false);
        if (form.email === '') {
            setError(true);
            setMsgError('Digite o seu e-mail');
            setLoading(false);
            return false;
        }

        await authApi.requestPassword(form.email)
        .then((response:any) => {
            setLoading(false);
            setPage('resetPassword');
        })
        .catch((error:any) => {
            setLoading(false);
            setError(true);
            switch (error.data.error) {
                default:
                    setMsgError('Erro desconhecido, entre em contato com o suporte');
                    break;
            }
            return false;
        });
    }

    async function resetPassword() {    
        setLoading(true);
        setError(false);
        if (form.code === '') {
            setError(true);
            setMsgError('Digite o código de confirmação');
            setLoading(false);
            return false;
        }
        if (form.password === '') {
            setError(true);
            setMsgError('Digite sua senha');
            setLoading(false);
            return false;
        }
        if (form.password !== form.confirm) {
            setError(true);
            setMsgError('As senhas não conferem');
            setLoading(false);
            return false;
        }

        await authApi.resetPassword(form)
        .then((response:any) => {
            setLoading(false);
            setPage('login');
        })
        .catch((error:any) => {
            setLoading(false);
            setError(true);
            switch (error.data.error) {
                default:
                    setMsgError('Erro desconhecido, entre em contato com o suporte');
                    break;
            }
            return false;
        });
    }

    return (
        <div className="page-login">
            <div className="apresentation">
                <div className="description">
                    <img src={logo} alt="" />
                    <p>by. Minha Plataforma LTDA</p>
                </div>
            </div>
            <div className="formLogin">
                {page === 'login' &&
                    <div className="boxLogin">
                        <h2>Faça login para começar</h2>
                        {error && <div className='msg-error'>{msgError}</div>}
                        <form>
                            <div className="formGroup">
                                <label htmlFor="user">Email</label>
                                <input
                                    type="text"
                                    value={form.email}
                                    onChange={(val) => setForm({ ...form, email: val.target.value })}
                                    className="user"
                                />
                            </div>
                            <div className="formGroup">
                                <label htmlFor="user">Senha</label>
                                <input
                                    type="password"
                                    value={form.password}
                                    onChange={(val) => setForm({ ...form, password: val.target.value })}
                                    className="pass"
                                />
                                <p className="clickable legend" onClick={() => setPage('requestPassword')}>Esqueci minha senha</p>
                            </div>
                            <div className="actions">
                                <button type="button" className="btn-invert" onClick={() => setPage('signup')}>Cadastrar-se</button>
                                <button type="button" onClick={() => logar()}>{loading ? <LoadingButton /> : 'Entrar'}</button>
                            </div>
                        </form>
                    </div>
                }

                {page === 'signup' &&
                    <div className="boxLogin">
                        {error && <div className='msg-error'>{msgError}</div>}
                        <h2>Cadastre-se</h2>
                        <form>
                            <div className="formGroup">
                                <label htmlFor="user">Nome da Empresa</label>
                                <input type="text"
                                    value={form.company}
                                    onChange={(val) => setForm({ ...form, company: val.target.value })}/>
                            </div>
                            <div className="formGroup">
                                <label htmlFor="user">CNPJ ou CPF</label>
                                <InputMask type="text"
                                    mask={form.cnpj.length <= 14 ? "999.999.999-999" : "99.999.999/9999-99"}  
                                    maskPlaceholder={null}              
                                    value={form.cnpj}
                                    onChange={(val) => setForm({...form, cnpj: val.target.value })} />
                            </div>
                            <div className="formGroup">
                                <label htmlFor="user">Nome</label>
                                <input type="text"
                                    value={form.name}
                                    onChange={(val) => setForm({ ...form, name: val.target.value })}/>
                            </div>
                            <div className="formGroup">
                                <label htmlFor="user">Email</label>
                                <input type="email"
                                    value={form.email}
                                    onChange={(val) => setForm({ ...form, email: val.target.value })} />
                            </div>
                            <div className="formGroup">
                                <label htmlFor="user">Senha</label>
                                <input type="password"
                                    value={form.password}
                                    onChange={(val) => setForm({ ...form, password: val.target.value })} />
                            </div>
                            <div className="formGroup">
                                <label htmlFor="user">Confirme a senha</label>
                                <input type="password"
                                    value={form.confirm}
                                    onChange={(val) => setForm({ ...form, confirm: val.target.value })}/>
                            </div>
                            <div className="actions">
                                <button type="button" className="btn-invert" onClick={() => setPage('login')}>Cancelar</button>
                                <button type="button" onClick={() => register()}>{loading ? <LoadingButton /> : 'Cadastrar'}</button>
                            </div>
                        </form>
                    </div>
                }

                {page == 'requestPassword' &&
                    <div className="boxLogin">
                        <h2>Esqueci minha senha</h2>
                        {error && <div className='msg-error'>{msgError}</div>}
                        <form>
                            <div className="formGroup">
                                <label htmlFor="user">Email cadastrado</label>
                                <input
                                    type="text"
                                    value={form.email}
                                    onChange={(val) => setForm({ ...form, email: val.target.value })}
                                    className="user"
                                />
                            </div>
                            <div className="actions">
                                <button type="button" className="btn-invert" onClick={() => setPage('login')}>Cancelar</button>
                                <button type="button" onClick={() => requestPassword()}>{loading ? <LoadingButton /> : 'Enviar codigo'}</button>
                            </div>
                        </form>
                    </div>
                }

                {page == 'resetPassword' &&
                    <div className="boxLogin">
                        <h2>Esqueci minha senha</h2>
                        {error && <div className='msg-error'>{msgError}</div>}
                        <form>
                            <div className="formGroup">
                                <label htmlFor="user">Codigo de confirmação</label>
                                <input
                                    type="number"
                                    value={form.code}
                                    onChange={(val) => setForm({ ...form, code: val.target.value })}
                                    className="user"
                                />
                                <label htmlFor="user">Senha</label>
                                <input
                                    type="password"
                                    value={form.password}
                                    onChange={(val) => setForm({ ...form, password: val.target.value })}
                                    className="user"
                                />
                                <label htmlFor="user">Confirme a senha</label>
                                <input
                                    type="password"
                                    value={form.confirm}
                                    onChange={(val) => setForm({ ...form, confirm: val.target.value })}
                                    className="user"
                                />
                            </div>
                            <div className="actions">
                                <button type="button" className="btn-invert" onClick={() => setPage('login')}>Cancelar</button>
                                <button type="button" onClick={() => resetPassword()}>{loading ? <LoadingButton /> : 'Enviar codigo'}</button>
                            </div>
                        </form>
                    </div>
                }
            </div>
        </div>
    );
}
