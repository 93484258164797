import { useContext, useEffect, useState } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import MaskLoading from "./components/maskLoading";

import Layout from "./components/theme/layout/layout";

import { GlobalContext } from "./context/globalContext";

import HomePage from "./pages/home/home";
import LoginPage from "./pages/login/login";
import SetupPage from "./pages/login/setup";
import Users from "./pages/users";
import Task from "./pages/task";
import Role from "./pages/role";
import Report from "./pages/report";

export default function Routes(props: any) {
  const context = useContext<any>(GlobalContext);

  useEffect(() => {
    context.getMe();
  }, []);

  if (context.loading) {
    return <MaskLoading />
  }

  if (!context.user) {
    return (
      <BrowserRouter>
        <Switch>
          <CustomRoute singlePage exact path="/" component={() => <LoginPage />} />
          <CustomRoute path="/login" singlePage component={() => <LoginPage />} />
        </Switch>
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
      <Switch>
        {/* <CustomRoute showMenu exact path="/admin" component={() => <HomePage />} /> */}
        <CustomRoute showMenu exact path="/" component={() => <HomePage />} />
        <CustomRoute showMenu path="/admin" component={() => <HomePage />} />
        <CustomRoute showMenu path="/user" component={() => <Users />} />
        <CustomRoute showMenu path="/task" component={() => <Task />} />
        <CustomRoute showMenu path="/role" component={() => <Role />} />
        <CustomRoute showMenu path="/report" component={() => <Report />} />
        <CustomRoute path="/setup" singlePage component={() => <SetupPage />} />
      </Switch>
    </BrowserRouter>
  );
};

const CustomRoute = ({ ...rest }) => {

  return (
    <>
      {!rest.singlePage && <Layout data={rest} />}
      <Route {...rest} />
    </>
  );
};