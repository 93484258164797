import api from './api';
export const TOKEN_KEY = "token";
export const isAuthenticated = () => true;

export const getToken = () => localStorage.getItem(TOKEN_KEY);


export const authApi = {
    login: async(data:any) => {
        return await api.post('/login', data)
        .then(async (response: any) => {
                console.log(response);
            
            if (response.data.token) {
                await localStorage.setItem("token", response.data.token);
            }
            return response.data;
        })
        .catch((error:any) => {
            throw error.response;
        });
    },
    me: async() => {
        return await api.get('/user/profile')
        .then((response:any) => {
            return response.data;
        })
        .catch((error:any) => {
            throw error.response;
        });
    },
    signup: async(data:any) => {
        return await api.post('/signup', data)
        .then(async (response:any) => {
            if (response.data.token) {
                await localStorage.setItem("token", response.data.token);
            }
            return response.data;
        })
        .catch((error:any) => {
            throw error.response;
        });
    },
    setUp: async(data:any) => {
        let i = localStorage.getItem('i');
        return await api.post('/gerente/setup?i='+i, data)
        .then((response:any) => {
            return response.status;
        })
        .catch((error:any) => {
            throw error.response;
        });
    },
    requestPassword: async(email: string) => {
        return await api.post('/auth/request_password', {email: email})
        .then((response:any) => {
            return response.status;
        })
        .catch((error:any) => {
            throw error.response;
        });
    },
    resetPassword: async(data: any) => {
        return await api.post('/auth/reset_password', data)
        .then((response:any) => {
            return response.status;
        })
        .catch((error:any) => {
            throw error.response;
        });
    }
}
export default authApi;