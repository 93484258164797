import api from './api';

const role = {
    getAll: async() => {
        let i = localStorage.getItem('i');
        return await api.get('/user_role?i='+i)
        .then((response:any) => {
            return response.data;
        })
        .catch((error:any) => {
            console.log(error.response);
            return null;
        });
    },
    save: async(data : any) => {
        let i = localStorage.getItem('i');
        return await api.post('/user_role?i='+i, data)
        .then((response:any) => {
            return response.data;
        })
        .catch((error:any) => {
            console.log(error.response);
            return null;
        });
    },
    update: async(data : any) => {
        let i = localStorage.getItem('i');
        return await api.put('/user_role/'+data.id+'?i='+i, data)
        .then((response:any) => {
            return response.data;
        })
        .catch((error:any) => {
            console.log(error.response);
            return null;
        });
    },

}

export default role;