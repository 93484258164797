import { useState, useEffect, useContext } from "react";
import { GlobalContext } from "../../context/globalContext";
import './style.scss';
import Table from 'react-bootstrap/Table';
import { Button, Col, Form, Row } from "react-bootstrap";

import authApi from "../../services/auth";
import companyApi from "../../services/companyService";

export default function HomePage() {
    const context = useContext<any>(GlobalContext);
    const [companies, setCompanies] = useState<any>([]);
    const [addingNewCompany, setAddingNewCompany] = useState<boolean>(false);
    const [newCompany, setNewCompany] = useState({
        name: '',
        cnpj: ''
    });

    useEffect(() => {
        getCompanies();
    }, []);

    async function getCompanies(){
        let response = await authApi.me();
        console.log(response);
        if (response && response.companies) {   
            setCompanies(response.companies);
        }
    }

    async function report(id:any){
        await localStorage.setItem('i', id);
        window.location.href = window.location.origin+'/report';
    }

    async function editCompany(id:any){
        await localStorage.setItem('i', id);
        window.location.href = window.location.origin+'/setup';
    }

    async function addCompany(){
        if (newCompany.cnpj && newCompany.name) {
            let response = await companyApi.add(newCompany);
            console.log(response);
            setNewCompany({
                name: '',
                cnpj: ''
            });
            await localStorage.setItem('i', response.id);
            getCompanies();
            window.location.href = window.location.origin+'/setup';
        }
        setAddingNewCompany(!addingNewCompany);
    }

    return (
        <section className="page-home">
            <div className="header-crud">
                <h1>Minhas empresas</h1>
                <div className="float-right">
                    {addingNewCompany && 
                        <Button variant="secundary" onClick={() => setAddingNewCompany(false)}>
                            Cancelar
                        </Button>
                    }
                    <Button variant="primary" onClick={() => addCompany()}>
                        {!addingNewCompany ? 'Criar empresa' : 'Salvar empresa'}
                    </Button>
                </div>
            </div>
            {!addingNewCompany ?
                <div className="card">
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Nome</th>
                                <th></th>
                            </tr>
                        </thead>    
                        <tbody>
                            { companies && companies.length > 0 && companies.map((row:any) => 
                                <tr key={row.id}>
                                    <td>{row.id}</td>
                                    <td>{row.name}</td>
                                    <td>
                                        <button className="btn btn-light btn-sm" onClick={() => report(row.id)}>
                                            Ver relatórios
                                        </button>
                                        <button className="btn btn-light btn-sm" onClick={() => editCompany(row.id)}>
                                            Configura esta empresa
                                        </button>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </div>
                :
                <div className="card">
                    <Form.Group as={Row} >
                        <Col sm={8}>
                            <Form.Label>Nome da empresa</Form.Label>
                            <Form.Control type="name" placeholder="Nome" 
                                value={newCompany.name ? newCompany.name : "" }
                                onChange={(val) => setNewCompany({...newCompany, name: val.target.value})} 
                            />
                        </Col>
                        <Col sm={2}>
                            <Form.Label>CPF / CNPJ</Form.Label>
                            <Form.Control type="name" placeholder="CPF / CNPJ" 
                                value={newCompany.cnpj ? newCompany.cnpj : "" }
                                onChange={(val) => setNewCompany({...newCompany, cnpj: val.target.value})} 
                            />
                        </Col>
                        <Col sm={2}>
                            <Form.Label>&nbsp;</Form.Label><br/>
                            <Button variant="primary" onClick={() => addCompany()}>Salvar</Button>
                        </Col>
                    </Form.Group>
                </div>
            }
        </section>
    )
}
