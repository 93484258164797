import { useState, useEffect } from "react";
import './style.scss';
import Table from 'react-bootstrap/Table';

import departmentApi from "../../services/departmentService";
import { FaEdit } from "react-icons/fa";
import InputMask from 'react-input-mask'
import { Button, Col, Container, Form, Row } from "react-bootstrap";

interface data {
    id: any,
    name: string,
    head_id: boolean
}
type valuesCrud = data[];
const initialValue = {
    id: null,
    name: '',
    head_id: false
}
  
export default function Department() {
    const [departments, setDepartments] = useState<valuesCrud>([]);
    const [department, setDepartment] = useState<data>(initialValue);
    const [editDepartment, setEditDepartment] = useState<boolean>(false);
    useEffect(() => {
        getDepartments();
    }, []);
  
    async function getDepartments(){
        let response = await departmentApi.getAll();
        setDepartments(response.data);
    }
    async function save() {
        if (department.id) {
            let response = await departmentApi.update(department);
        } else {
            let response = await departmentApi.save(department);
        }
        setEditDepartment(false);
        setDepartment(initialValue);
        getDepartments();
    }
    return (
      <section className="page-home">
            <div className="card">
                {!editDepartment ? <>
                    <div className="header-crud">
                        <h1>Departamentos</h1>
                        <div className="float-right">
                            <Button variant="primary" onClick={() => {
                                setDepartment(initialValue);
                                setEditDepartment(true)
                            }}>
                                Criar departamento
                            </Button>{'  '}
                        </div>
                    </div>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>Cargo</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            { departments && departments.length > 0 && departments.map((row) => 
                                <tr key={row.id}>
                                    <td>{row.name}</td>
                                    <td>
                                        <a href="#" onClick={() => {
                                            setEditDepartment(true); 
                                            setDepartment(row)}}><FaEdit /></a>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </> :
                    <Container>
                        <Form.Group as={Row} >
                            <Col sm={8}>
                                <Form.Label>Nome</Form.Label>
                                <Form.Control type="name" placeholder="Nome" 
                                    value={department.name ? department.name : "" }
                                    onChange={(val) => setDepartment({...department, name: val.target.value})} 
                                />
                            </Col>

                        </Form.Group>
                        <Button variant="primary" onClick={() => save()}>Salvar</Button>{'  '}
                        <Button variant="secondary" onClick={() => setEditDepartment(false)}>Cancelar</Button>
                    </Container>
                }
            </div>
      </section>
    );
}
  