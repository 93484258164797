import api from './api';

const role = {
    getAll: async() => {
        let i = localStorage.getItem('i');
        return await api.get('/user_task?i='+i)
        .then((response:any) => {
            return response.data;
        })
        .catch((error:any) => {
            console.log(error.response);
            return null;
        });
    },
    save: async(data : any) => {
        let i = localStorage.getItem('i');
        return await api.post('/user_task?i='+i, data)
        .then((response:any) => {
            return response.data;
        })
        .catch((error:any) => {
            console.log(error.response);
            return null;
        });
    },
    update: async(data : any) => {
        let i = localStorage.getItem('i');
        return await api.put('/user_task/'+data.id+'?i='+i, data)
        .then((response:any) => {
            return response.data;
        })
        .catch((error:any) => {
            console.log(error.response);
            return null;
        });
    },
    report: async (data: any) => {
        let i = localStorage.getItem('i');
        return await api.get('/user_task/admin_report?i=' + i, data)
            .then((response: any) => {
                return response.data;
            })
            .catch((error: any) => {
                console.log(error.response);
                return null;
            });
    }
}

export default role;