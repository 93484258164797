import { useState, useEffect } from "react";
import './style.scss';
import Table from 'react-bootstrap/Table';

import companyApi from "../../services/companyService";
import taskApi from "../../services/taskService";

import { Col, Row, Card } from "react-bootstrap";

export default function Report() {

	const [report, setReport] = useState<any>({});
	const [company, setCompany] = useState({ name: "" });
	const [loading, setLoading] = useState<boolean>(true);

	useEffect(() => {
		getCompany();
		getReport();
	}, []);

	async function getCompany() {
		let response = await companyApi.get();
		setCompany(response);
	}

	async function getReport() {
		let response = await taskApi.report({});
		setReport(response);
		setLoading(false);
	}

	return (

		<section className="page-report">
			{loading ?
				<div className="loading">Carregando...</div>
				: <>
					<Row>
						<Col md={12} >
							<Card className="cardTotal">
								<span className="title">
									{company.name}
								</span>
							</Card>
						</Col>
					</Row>
					<Row>
						<Col md={6} >
							<Card className="cardTotal">
								<span className="number">
									{report.total.total_tasks}
								</span>

								Tarefas
							</Card>
						</Col>
						<Col md={6} >
							<Card className="cardTotal">
								<span className="number">
									{report.total.total_incidents}
								</span>

								Ocorrências
							</Card>
						</Col>
					</Row>
					<Row>
						<Col md={4} >
							<Card className="cardTotal">
								<span className="number success">
									{report.total.total_performed_tasks}
								</span>
								Concluidas
							</Card>
						</Col>
						<Col md={4} >
							<Card className="cardTotal">
								<span className="number danger">
									{report.total.total_late}
								</span>
								Atrasadas
							</Card>
						</Col>
						<Col md={4} >
							<Card className="cardTotal">
								<span className="number">
									{report.total.total_pending}
								</span>
								Pendentes
							</Card>
						</Col>
					</Row>
					<Row>
						<Col md={12} >
							<Card>
								Ocorrências
								<hr />
								<Table>
									<thead>
										<tr>
											<th>Tipo</th>
											<th>Qtd Fechada</th>
											<th>Qtd Pendente</th>
											<th>Qtd Atrasado</th>
											<th>Total</th>
										</tr>
									</thead>
									<tbody>
										{report.indident_type.map((incident: any) =>
											<tr>
												{incident.type == 'incident' && <td>Ocorrência</td>}
												{incident.type == 'request' && <td>Solicitação</td>}
												{incident.type == 'announcement' && <td>Comunicado</td>}
												<td>{incident.done}</td>
												<td>{incident.pending}</td>
												<td>{incident.late}</td>
												<td>{incident.total}</td>
											</tr>
										)}
									</tbody>
								</Table>
							</Card>
						</Col>
					</Row>
					<Row>
						<Col md={6} >
							<Card>
								Usuário
								<hr />
								<Table>
									<thead>
										<tr>
											<th>Usuario</th>
											<th>Concluidas</th>
											<th>Atrasadas</th>
											<th>Pendentes</th>
											<th>Ocorrências</th>
										</tr>
									</thead>
									<tbody>
										{report.users.map((user: any) =>
											<tr key={user.id}>
												<td>{user.name ?? 'Sem responsável'}</td>
												<td>{user.total_performed_tasks}</td>
												<td>{user.total_late}</td>
												<td>{user.total_pending}</td>
												<td>{user.total_incidents}</td>
											</tr>
										)}
									</tbody>
								</Table>
							</Card>
						</Col>
						<Col md={6} >
							<Card>
								Tarefa por área
								<hr />
								<Table>
									<thead>
										<tr>
											<th>Área</th>
											<th>Concluidas</th>
											<th>Atrasadas</th>
											<th>Pendentes</th>
											<th>Total</th>
										</tr>
									</thead>
									<tbody>
										{report.departments.map((department: any) =>
											<tr>
												<td>{department.name}</td>
												<td>{department.total_performed_tasks}</td>
												<td>{department.total_late}</td>
												<td>{department.total_tasks - department.total_late - department.total_performed_tasks}</td>
												<td>{department.total_tasks}</td>
											</tr>
										)}
									</tbody>
								</Table>
							</Card>
						</Col>
					</Row>

				</>
			}
		</section>
	);

}