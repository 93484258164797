import { NavLink } from "react-router-dom";
import './style.scss';

export default function Menu() {
    return (
        <div className="mainMenu">
            <ul>
                <li>
                    <NavLink activeClassName="active" exact to="/admin" >
                        Empresas
                    </NavLink>
                </li>
                {/* <li>
                    <NavLink activeClassName="active" exact to="/user" >
                        Usuários
                    </NavLink>
                </li>
                <li>
                    <NavLink activeClassName="active" exact to="/role" >
                        Departamentos
                    </NavLink>
                </li>
                <li>
                    <NavLink activeClassName="active" exact to="/task" >
                        Tarefas
                    </NavLink>
                </li> */}
            </ul>
        </div>
    );
}
