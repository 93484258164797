import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../context/globalContext";
import Menu from "../menu/menu";
import './style.scss';
import {FiLogOut} from 'react-icons/fi';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Modal from 'react-bootstrap/Modal';
import { Button } from "react-bootstrap";
import logo from '../../../img/logo.svg';

export default function Layout(props: any) {
    const context = useContext<any>(GlobalContext);
    const [show, setShow] = useState(false);

    useEffect(() => {
        if(props.data.showMenu){
            document.body.classList.add('showMenu');
        }else{
            document.body.classList.remove('showMenu');
        }
    },[props])

    return (
        <>
            <div className="mainHeader">
                <div className="logo">
                    <img src={logo} alt="" />
                    <h1>Gerente de Bolso</h1>
                </div>
                
                <div className="user">
                    

                    <span>Bem vindo(a): {context.user?.name} </span>
                    <OverlayTrigger
                    key='bottom'
                    placement='bottom'
                    overlay={
                        <Tooltip id='logout'>
                            Sair
                        </Tooltip>
                    }>
                        <span className="logout-icon" onClick={() => setShow(true)}><FiLogOut className="icon" /></span>
                    </OverlayTrigger>
                        
                </div>
            </div>
            {props.data.showMenu && <Menu />}
            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Body>Deseja realmente sair?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" size="sm" onClick={() => setShow(false)}>
                        Não
                    </Button>
                    <Button variant="primary" size="sm" onClick={() => context.onLogout()}>
                        Sim
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
